import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"

class MentionsLegales extends React.Component {
  render() {
    return (
      <>
        <Layout banner={false} location={this.props.location}>
          <SEO title="Mentions légales" description="Mentions légales site Encadrement sur mesure" robots="noindex" />
          <h1>Mentions légales</h1>

          <h2>Éditeur</h2>
          <span>SARL LES PROFESSIONNELS DU CADRE : Zone Artisanale, route d’Emagny - 25115 Pouilley-les-Vignes (France).</span>
          <ul>
            <li style={{ listStyleType: "disc", marginLeft: "1.6rem" }}>SARL au capital de 26000 €</li>
            <li style={{ listStyleType: "disc", marginLeft: "1.6rem" }}>RCS BESANÇON B 409 786 290</li>
            <li style={{ listStyleType: "disc", marginLeft: "1.6rem" }}>SIRET 40978629000026</li>
          </ul>
          <span>Responsable de publication : Olivier Devaux</span>

          <h2>Développement</h2>
          <p>
            APROGSYS
            <br />
            14 rue Lafayette
            <br />
            Parc BeePos
            <br />
            Bâtiment B
            <br />
            25000 Besançon
            <br />
            +33 (0)6 74 95 90 18
            <br />
            +33 (0)3 81 47 70 38
            <br />
            <span style={{ color: "red" }}>www.aprogsys.com</span>
          </p>

          <br />
          <h2>Hébergement</h2>
          <p>
            OVH
            <br />2 rue Kellermann - 59100 Roubaix - France
          </p>

          <br />
          <h2>Propriété intellectuelle et Copyright</h2>
          <p>Le site est protégé par les lois en vigueur sur la propriété intellectuelle et le droit d&lsquo;auteur au niveau national et international.</p>
          <p>
            Les ressources et informations disponibles sur le Site sont protégées par un copyright et sont propriété des Professionnels du Cadre, sous réserve
            de droits appartenant à des tiers.
          </p>

          <br />
          <h2>Limitation de la responsabilité</h2>
          <p>
            Les Professionnels du Cadre est soucieux de diffuser sur son site internet des informations complètes et exactes sans pouvoir toutefois en garantir
            l&lsquo;exactitude ou l&lsquo;exhaustivité.
          </p>
          <p>
            Dans les limites autorisées par la loi, notamment en cas de négligence, Les Professionnels du Cadre ne pourra être tenu responsable de pertes ou de
            dommages de quelque nature que ce soit, qu&lsquo;il s&lsquo;agisse, notamment mais non exclusivement, de dommages directs, indirects ou consécutifs,
            liés à l&lsquo;accès au Site ou à tout autre site ayant un lien avec le Site, à son utilisation, à son exploration ou à son téléchargement.
          </p>
        </Layout>
      </>
    )
  }
}

//export default MentionsLegales

MentionsLegales.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(MentionsLegales)
